import React, {createContext, FC, useEffect, useState} from "react";
import { getCustomStoreElements } from "../services/storesApi";
import { extractDistributorToken } from "../utils";

type CustomStoreElementsContext = {
    delaiStandard: string | null,
    delaiOnRequest: string | null,
    mailDestinataireBOM: string | null,
    titleBlocHome: string | null,
    textBlocHome: string | null,
    labelButtonBlocHome: string | null,
    linkButtonBlocHome: string | null,
    mailContact: string | null,
    jauneParker: string | null,
    noirParker: string | null,
    blancParker: string | null,
    customLanguages: string[],
    isCustomStore: boolean,
    isLoading: boolean
}

type CustomStoreElementsContextProviderType = {
    children: any
}

export const CustomStoreElementsContext = createContext<CustomStoreElementsContext>({
    delaiStandard: null,
    delaiOnRequest: null,
    mailDestinataireBOM: null,
    titleBlocHome: null,
    textBlocHome: null,
    labelButtonBlocHome: null,
    linkButtonBlocHome: null,
    mailContact: null,
    jauneParker: null,
    noirParker: null,
    blancParker: null,
    customLanguages: [],
    isCustomStore: false,
    isLoading: false
})

export const CustomStoreElementsContextProvider: FC<CustomStoreElementsContextProviderType> = ({children}) => {
    const token = extractDistributorToken()
    const [ delaiStandard, setDelaiStandard] = useState<string>("")
    const [ delaiOnRequest, setDelaiOnRequest] = useState<string>("")
    const [ mailDestinataireBOM, setMailDestinataireBOM] = useState<string | null>(null)
    const [ customLanguages, setCustomLanguages ] = useState<string[]>([])
    const [ titleBlocHome, setTitleBlocHome] = useState<string | null>(null)
    const [ textBlocHome, setTextBlocHome] = useState<string | null>(null)
    const [ labelButtonBlocHome, setLabelButtonBlocHome] = useState<string | null>(null)
    const [ linkButtonBlocHome, setLinkButtonBlocHome] = useState<string | null>(null)
    const [ mailContact, setMailContact ] = useState<string | null>(null)
    const [ jauneParker, setJauneParker] = useState<string | null>(null)
    const [ noirParker, setNoirParker] = useState<string | null>(null)
    const [ blancParker, setBlancParker] = useState<string | null>(null)
    const [ isCustomStore, setIsCustomStore] = useState<boolean>(false)
    const [ isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (token) {
            setIsLoading(true)
            getCustomStoreElements(token)
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        setIsCustomStore(true)
                        if (data.hasOwnProperty("info_delai_produits_standards")) {
                            setDelaiStandard(data.info_delai_produits_standards)
                        }
                        if (data.hasOwnProperty("info_delai_produits_on_request")) {
                            setDelaiOnRequest(data.info_delai_produits_on_request)
                        }
                        if (data.hasOwnProperty("email_destinataire_b_o_m")) {
                            setMailDestinataireBOM(data.email_destinataire_b_o_m)
                        }
                        if (data.hasOwnProperty("langues_autorisees")) {
                            setCustomLanguages(data.langues_autorisees)
                        }
                        if (data.hasOwnProperty("home_bloc_title")) {
                            setTitleBlocHome(data.home_bloc_title)
                        }
                        if (data.hasOwnProperty("home_bloc_text")) {
                            setTextBlocHome(data.home_bloc_text)
                        }
                        if (data.hasOwnProperty("home_bloc_button_label")) {
                            setLabelButtonBlocHome(data.home_bloc_button_label)
                        }
                        if (data.hasOwnProperty("home_bloc_button_link")) {
                            setLinkButtonBlocHome(data.home_bloc_button_link)
                        }
                        if (data.hasOwnProperty("email_contact")) {
                            setMailContact(data.email_contact)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_jaune_parker")) {
                            setJauneParker(data.couleur_remplacement_jaune_parker)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_noir_parker")) {
                            setNoirParker(data.couleur_remplacement_noir_parker)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_blanc_parker")) {
                            setBlancParker(data.couleur_remplacement_blanc_parker)
                        }
                    }
                })
                .catch(() => setIsCustomStore(false))
                .finally(() => setIsLoading(false))
        }
    }, [])

    return <CustomStoreElementsContext.Provider value={{
        delaiStandard,
        delaiOnRequest,
        mailDestinataireBOM,
        titleBlocHome,
        textBlocHome,
        labelButtonBlocHome,
        linkButtonBlocHome,
        mailContact,
        jauneParker,
        blancParker,
        noirParker,
        customLanguages,
        isCustomStore,
        isLoading
    }}>
        {children}
    </CustomStoreElementsContext.Provider>
}