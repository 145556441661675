import React, { useContext, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import CheckRadioButton from '../../../../components/form/radio/check/CheckRadioButton'
import LabelSubtitle from '../../../../components/typo/LabelSubtitle'
import { getFittingsWithFilters } from '../../services/fittingsApi'
import { extractMaterials, generateFittingsFilters, RANK_FITTING_A } from '../../utils/fittingsUtils'
import Loader from '../../../../components/global/Loader'
import ErrorLabel from '../../../../components/typo/ErrorLabel'
import { useTranslation } from 'react-i18next'
import { useCurrentStepContext } from '../../utils/utils'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import { CustomStoreElementsContext } from "../../../../utils/contextes/CustomStoreElementsContext";

const FittingMaterial = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [materials, setMaterials] = useState<Array<string>>([])
	const [materialSelected, setMaterialSelected] = useState<string>()
	const { setVisibilitySnackbar } = useContext(SnackContext)
	const { blancParker, noirParker } = useContext(CustomStoreElementsContext)

	useEffect(() => {
		getFittingsWithFilters(generateFittingsFilters(currentStep.rank!))
			.then((data: Array<FittingType>) => setMaterials(extractMaterials(data)))
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))

	}, [])

	useEffect(() => {
		if (materialSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: currentStep.rank === RANK_FITTING_A ? LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL : LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL,
						value: materialSelected
					}
				]
			})
		}
	}, [materialSelected])

	return isLoading ? (
		<Loader />
	) : (
		<Stack flexDirection="column" alignItems="flex-start" alignSelf="stretch">
			{(materials && materials.length > 0) ? materials.map((material: string) => (
				<CheckRadioButton key={material} value={material} itemSelected={materialSelected} setSelection={setMaterialSelected}>
					<LabelSubtitle color={
						materialSelected === material
							? !!blancParker ? blancParker : '#FFF'
							: !!noirParker ? noirParker : '#000'
					}>
						{material}
					</LabelSubtitle>
				</CheckRadioButton>
			)) : (
				<ErrorLabel>{t('errors.noData.fittings.material')}</ErrorLabel>
			)}
		</Stack>
	)
}

export default FittingMaterial