import LabelSubtitle from "../../../../../../typo/LabelSubtitle";
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Stack, styled } from "@mui/material";
import { LOCAL_STORAGE_KEYS } from "../../../../../../../config/enums";
import {PricesContext} from "../../../../../../../utils/contextes/PricesContext";

const SpecificityContainer = styled(Stack)({
    width: '36%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
    gap: '6px'
})

const Row = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '12px'
})
const QuantityItem = () => {

    const { t } = useTranslation()
    const { hosePrice } = useContext(PricesContext)

    return (
        <>
            <LabelSubtitle>{t('components.assemblyProgressionPanel.quantity.title')}</LabelSubtitle>
            <SpecificityContainer>
                <Row>
                    <LabelSubtitle color="#828282">
                        {t('components.assemblyProgressionPanel.price.unit')}
                    </LabelSubtitle>
                    <LabelSubtitle>{localStorage.getItem(LOCAL_STORAGE_KEYS.UNIT_PRICE)} €</LabelSubtitle>
                </Row>
                <Row>
                    <LabelSubtitle color="#828282">
                        {t('components.assemblyProgressionPanel.price.withService')}
                    </LabelSubtitle>
                    <LabelSubtitle>{localStorage.getItem(LOCAL_STORAGE_KEYS.SERVICE_PRICE)} €</LabelSubtitle>
                </Row>
                {localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY) && (
                    <Row>
                        <LabelSubtitle color="#828282">
                            {t('components.assemblyProgressionPanel.quantity.libelle')}
                        </LabelSubtitle>
                        <LabelSubtitle>{localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY)}</LabelSubtitle>
                    </Row>
                )}
                <Row>
                    <LabelSubtitle color="#828282">
                        {t('components.assemblyProgressionPanel.price.total')}
                    </LabelSubtitle>
                    <LabelSubtitle>{localStorage.getItem(LOCAL_STORAGE_KEYS.TOTAL_PRICE)} €</LabelSubtitle>
                </Row>
            </SpecificityContainer>
        </>


    )
}

export default QuantityItem