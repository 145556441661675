import React, {createContext, FC, useEffect, useState} from "react";
import { getCustomStoreElements } from "../services/storesApi";
import { extractDistributorToken } from "../utils";
import {LOCAL_STORAGE_KEYS} from "../../config/enums";
import {getPartPrice} from "../../modules/selector/services/storePartApi";

type PricesContext = {
    hosePrice: number | null,
    hosePricePerMeter: number | null,
    fittingAPrice: number | null,
    fittingBPrice: number | null,
}

type PricesContextProviderType = {
    children: any
}

export const PricesContext = createContext<PricesContext>({
    hosePrice: null,
    hosePricePerMeter: null,
    fittingAPrice: null,
    fittingBPrice: null,

})

export const PricesContextProvider: FC<PricesContextProviderType> = ({children}) => {

    const hoseStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER)
    const fittingAStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)
    const fittingBStorePartNumber =  localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER)
    const quantite =  localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY)
    const [ hosePrice, setHosePrice ] = useState<number>(0)
    const [ hosePricePerMeter, setHosePricePerMeter ] = useState<number>(0)
    const [ fittingAPrice, setFittingAPrice ] = useState<number>(0)
    const [ fittingBPrice, setFittingBPrice ] = useState<number>(0)

    useEffect(() => {
        if (!!hoseStorePartNumber) {
            getPartPrice(hoseStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            let price = Number(data) * Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)) / 1000
                            setHosePrice(price)
                            setHosePricePerMeter(Number(data))
                        }
                    }
                )
        }
        if (!!fittingAStorePartNumber) {
            getPartPrice(fittingAStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            setFittingAPrice(Number(data))
                        }
                    }
                )
        }
        if (!!fittingBStorePartNumber) {
            getPartPrice(fittingBStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            setFittingBPrice(Number(data))
                        }
                    }
                )
        }
    }, [])

    const unitPrice = hosePrice + fittingAPrice + fittingBPrice
    localStorage.setItem(LOCAL_STORAGE_KEYS.UNIT_PRICE, unitPrice.toFixed(2))
    const servicePrice = unitPrice
    localStorage.setItem(LOCAL_STORAGE_KEYS.SERVICE_PRICE, servicePrice.toFixed(2))
    const totalPrice = servicePrice * Number(quantite)
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOTAL_PRICE, totalPrice.toFixed(2))

    return <PricesContext.Provider value={{
        hosePrice,
        hosePricePerMeter,
        fittingAPrice,
        fittingBPrice
    }}>
        {children}
    </PricesContext.Provider>
}