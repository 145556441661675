import React, { FC, useContext, useState } from "react";
import { Box, Button, Modal, Stack, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LabelSubtitle from "../../typo/LabelSubtitle";
import { pdfBilling } from "../../../modules/selector/services/pdfApi";
import { LOCAL_STORAGE_KEYS } from "../../../config/enums";
import { DistributorContext } from "../../../utils/contextes/DistributorContext";
import { SnackContext } from "../../../utils/contextes/SnackContext";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';


type PersonalInformationModalProps = {
    open: boolean,
    hoseCuttingLength: number,
    onError: () => void,
    handleClose: () => void,
    handleConfirm: () => void
}

const BoxSelectionModal = styled(Box)(
    ({theme}) => ({
        display: 'flex',
        padding: '24px',
        width: '728px',
        flexDirection: 'column',
        gap: '16px',
        borderRadius: '12px',
        borderTop: '4px solid',
        borderColor: theme.palette.button.primary,
        backgroundColor: theme.palette.text.secondary,
        boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)'
    })
)

const ContentContainer = styled(Stack)({
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    gap: '10px',
    alignSelf: 'stretch',
    borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
})

const TitleTypo = styled(Typography)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
        textAlign: 'center',
        fontFamily: 'DIN 2014',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',
        fontSize: '24px'
    })
)

const SubtitleTypo = styled(Typography)(
    ({ theme }) => ({
        color: '#262626',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px'
    })
)

const ButtonsSection = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch'
})

const CSButton = styled(Button)({
    display: 'flex',
    flex: '1 0 0'
})

const RowInputs = styled(Stack)({
    flexDirection: 'row',
    padding: '0 20px',
    width: "80%",
    gap: '40px'
})

const CustomPhoneInput = styled(PhoneInput)({
    height: '100%',
    width: '100%',
    '& .react-international-phone-country-selector': {
        height: '100%',
        '& button': {
            height: '100%'
        }
    },
    '& .react-international-phone-input': {
        height: '100%',
        width: '100%'
    }
})

const InfoField = styled(TextField)(
    ({ theme }) => ({
        '& .MuiInputBase-input': {
            backgroundColor: theme.palette.secondary.main,
        },
        '& .MuiTextField-root': {
            backgroundColor: theme.palette.secondary.main
        }
    })
)


const PersonalInformationModal: FC<PersonalInformationModalProps> = (
    {
        open,
        hoseCuttingLength,
        onError,
        handleClose,
        handleConfirm
    }
) => {
    const { t } = useTranslation()
    const { token } = useContext(DistributorContext)
    const [ nom, setNom ] = useState<string>("")
    const [ prenom, setPrenom ] = useState<string>("")
    const [ mail, setMail ] = useState<string>("")
    const [ societe, setSociete ] = useState<string>("")
    const [ postalAddress, setPostalAddress ] = useState<string>("")
    const [ zipCode, setZipCode ] = useState<string>("")
    const [ ville, setVille ] = useState<string>("")
    const [ pays, setPays ] = useState<string>("")
    const [ phone, setPhone ] = useState<string>("")
    const { setMessageCode, setVisibilitySnackbar } = useContext(SnackContext)

    const handleSendPdf = () => {
        pdfBilling(JSON.stringify({
            send_billing: true,
            name: nom,
            firstname: prenom,
            mail: mail,
            society: societe,
            postal_address: postalAddress,
            zip_code: zipCode,
            country: pays,
            city: ville,
            phone: phone,
            hose_part_number: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER),
            fitting_part_number_1: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER),
            fitting_part_number_2: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER),
            total_length: localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH),
            cutting_length: hoseCuttingLength,
            fitting_angle: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTINGS_ANGLE),
            assembly_max_working_pressure: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE),
            quantity: localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY),
            store_token: token
        }))
            .then(pdf => {
                if (pdf) {
                    const fileURL = URL.createObjectURL(pdf)
                    window.open(fileURL)
                }
            })
            .catch(() => {
                setMessageCode('errors.customStoreElements.getStoreInformation')
                setVisibilitySnackbar(true)
                onError()
            })
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                position: 'absolute',
                top: '15%',
                left: '30%'
            }}
        >
            <form onSubmit={() => {
                handleSendPdf()
                handleConfirm()
            }}
            >
                <BoxSelectionModal>
                    <ContentContainer>
                        <TitleTypo>{t('modal.sendBOM.title')}</TitleTypo>
                        <SubtitleTypo>{t('modal.sendBOM.subtitle')}</SubtitleTypo>
                        <SubtitleTypo>{t('modal.sendBOM.subtitle2')}</SubtitleTypo>
                        <RowInputs>
                            <Stack style={{width: "100%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.name')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setNom(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                            <Stack style={{width: "100%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.firstname')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setPrenom(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                        </RowInputs>
                        <RowInputs>
                            <Stack style={{width: "100%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.mail')}</LabelSubtitle>
                                <InfoField
                                    type="email"
                                    required={true}
                                    onChange={
                                        e => {
                                            setMail(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                        </RowInputs>
                        <RowInputs>
                            <Stack style={{width: "50%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.society')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setSociete(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                            <Stack style={{width: "50%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.phone')}</LabelSubtitle>
                                <CustomPhoneInput
                                    defaultCountry={"fr"}
                                    required={true}
                                    onChange={(value) => setPhone(value)}
                                />
                            </Stack>
                        </RowInputs>
                        <RowInputs>
                            <Stack style={{width: "100%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.postalAddress')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setPostalAddress(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                        </RowInputs>
                        <RowInputs>
                            <Stack style={{width: "40%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.country')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setPays(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                            <Stack style={{width: "40%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.city')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setVille(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                            <Stack style={{width: "20%"}}>
                                <LabelSubtitle>{t('modal.sendBOM.zipCode')}</LabelSubtitle>
                                <InfoField
                                    type="text"
                                    required={true}
                                    onChange={
                                        e => {
                                            setZipCode(e.target.value)
                                        }
                                    }
                                />
                            </Stack>
                        </RowInputs>
                    </ContentContainer>
                    <ButtonsSection>
                        <CSButton
                            variant="outlined"
                            onClick={() => handleClose()}
                        >
                            {t('modal.changeSelection.cancel')}
                        </CSButton>
                        <CSButton
                            variant="contained"
                            type="submit"
                        >
                            {t('modal.changeSelection.confirm')}
                        </CSButton>
                    </ButtonsSection>
                </BoxSelectionModal>
            </form>
        </Modal>
    )
}


export default PersonalInformationModal