import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_KEY } from '../../../utils/constants'
import { Select, Stack, styled } from '@mui/material'
import SelectOption from '../../form/select/SelectOption'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { clearLocalStorage } from "../../../utils/selectorUtils";
import { useNavigate } from "react-router-dom";
import { CustomStoreElementsContext } from "../../../utils/contextes/CustomStoreElementsContext";

const Container = styled(Stack)(
	({ theme }) => ({
		color: theme.palette.text.secondary,
		border: 'none'
	})
)

const SelectionInput = styled(Select)(
	({ theme }) => ({
		color: theme.palette.text.secondary,
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '18px',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		paddingRight: '18px',
		backgroundColor: theme.palette.primary.main,
		'& .MuiSvgIcon-root ': {
			fill: `${theme.palette.secondary.main} !important`,
		}
	})
)


const NavbarLanguageSelector: FC = () => {
	const { i18n } = useTranslation()
	const navigate = useNavigate()

	const { customLanguages } = useContext(CustomStoreElementsContext)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		clearLocalStorage()

		localStorage.setItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE, event.target.value)
		i18n.changeLanguage(event.target.value)
		navigate('/')
	}

	return (
		<Container>
			<SelectionInput IconComponent={UnfoldMoreIcon} value={i18n.language.split('-')[0]} onChange={handleChange}>
				{i18n.languages.filter(lng => !lng.includes('-') && ( customLanguages.length === 0 || customLanguages.includes(lng.toUpperCase())) ).map(lng => (
					<SelectOption value={lng} key={lng}>{lng.toUpperCase()}</SelectOption>
				))}
			</SelectionInput>
		</Container>
	)
}

export default NavbarLanguageSelector
